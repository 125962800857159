import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";
import "../../../index.css";
import App from "./App.tsx";
import { initThirdPartyApis } from "./api/third-party.ts";

initThirdPartyApis();

const INTERCOM_APP_ID = import.meta.env.VITE_INTERCOM_APP_ID;
const router = createBrowserRouter(
  createRoutesFromElements(<Route path="*" element={<App />} />),
);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <IntercomProvider autoBoot appId={INTERCOM_APP_ID}>
    <RouterProvider router={router} />
  </IntercomProvider>,
);

if (import.meta.env.DEV) {
  loadDevMessages();
  loadErrorMessages();
}
