import * as Sentry from "@sentry/react";
import { loadStripe } from "@stripe/stripe-js";
import Amplitude from "amplitude-js";
import { version } from "../../package.json";

export const amplitude = Amplitude.getInstance();

export function initThirdPartyApis() {
  initFbq();
  initAmplitude();
  initSentry();
}

export async function redirectToStripeSession(sessionId: string) {
  const stripe = await loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);
  await stripe?.redirectToCheckout({ sessionId });
}

export function trackFbq(event: string) {
  if (
    typeof window !== "undefined" &&
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    typeof (window as any).fbq === "function"
  ) {
    fbq("track", event);
  }
}

function initAmplitude() {
  if (!import.meta.env.VITE_AMPLITUDE_ID) return;

  const url = new URL(window.location.href);
  const deviceId = url.searchParams.get("adid") ?? "";

  amplitude.init(import.meta.env.VITE_AMPLITUDE_ID, undefined, { deviceId });

  if (deviceId) {
    url.searchParams.delete("adid");
    history.replaceState(null, "", url);
  }
}

function initSentry() {
  if (!import.meta.env.VITE_SENTRY_DSN) return;

  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [Sentry.replayIntegration()],
    release: version,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

function initFbq() {
  if (import.meta.env.VITE_SENTRY_ENVIRONMENT === "production") {
    const script = document.createElement("script");
    script.innerHTML = `
!(function (f, b, e, v, n, t, s) {
  if (f.fbq) return;
  n = f.fbq = function () {
    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
  };
  if (!f._fbq) f._fbq = n;
  n.push = n;
  n.loaded = !0;
  n.version = "2.0";
  n.queue = [];
  t = b.createElement(e);
  t.async = !0;
  t.src = v;
  s = b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t, s);
})(
  window,
  document,
  "script",
  "https://connect.facebook.net/en_US/fbevents.js",
);
fbq("init", "634910782429340");
fbq("track", "PageView");
`;
    document.head.appendChild(script);
  }
}
