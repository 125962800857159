import {
  Badge,
  Button,
  IconButton,
  Tab,
  TabList,
  Tabs,
  Text,
  useMediaQuery,
} from "@archery-inc/design-system";
import { Suspense } from "react";
import { TabPanel } from "react-aria-components";
import { FormattedMessage } from "react-intl";
import { Outlet, useLocation } from "react-router-dom";
import { useProPlanPricesQueryPrefetch } from "../api/graphql/planPrices";
import { useProfileQuery } from "../api/graphql/profile";
import { useGetAiTokensPrefetch } from "../api/graphql/subscription";
import { amplitude } from "../api/third-party";
import { useMainStore } from "../common/store";
import { Model } from "../pages/CreatePage/store/aiStore";
import { useAiStore } from "../pages/CreatePage/store/selectors";

export default function AiRevealsLayout() {
  const updateModel = useAiStore.use.setModel();
  const originalFileInfo = useAiStore.use.originalFileInfo();
  const location = useLocation();
  const prefetchProPrices = useProPlanPricesQueryPrefetch();
  const prefetchTokenPrices = useGetAiTokensPrefetch();

  const showTabs = !originalFileInfo && location.pathname === "/";
  const showPresetTitle = originalFileInfo && location.pathname === "/";
  return (
    <div
      onMouseOver={() =>
        void Promise.all([prefetchProPrices(), prefetchTokenPrices()])
      }
      className="h-full flex flex-col pt-12"
    >
      <div className="h-15 flex justify-between border-b-1 fixed top-0 left-1 sm:left-66 right-0 border-grey-3 gap-2 items-center z-20 bg-white/95">
        {showTabs && (
          <Tabs
            className="h-full w-full"
            onSelectionChange={(k) => updateModel(k as Model)}
          >
            <TabList className="h-full w-full justify-center">
              <Tab id="runway" variant="fullwidth" leftIcon="cosmetics">
                <FormattedMessage id="ai_reveal_create_tab_products" />
              </Tab>
              <Tab
                id="animated-controlnets"
                variant="fullwidth"
                leftIcon="round_star"
              >
                <FormattedMessage id="ai_reveal_create_tab_logo" />
              </Tab>
            </TabList>
            <TabPanel id="runway" className="hidden" />
            <TabPanel id="animated-controlnets" className="hidden" />
          </Tabs>
        )}
        {showPresetTitle && (
          <Text weight="medium" className="px-5 text-2xl">
            <FormattedMessage id="ai_reveal_choose_a_preset_txt" />
          </Text>
        )}
        <div /> {/* Spacer */}
        <TokensButton />
      </div>
      <Suspense fallback={null}>
        <Outlet />
      </Suspense>
    </div>
  );
}

function TokensButton() {
  const { data: user } = useProfileQuery({ fetchPolicy: "cache-and-network" });
  const isMobile = useMediaQuery("lg");
  const togglePaywall = useMainStore((state) => state.togglePaywall);

  function buyTokens() {
    amplitude.logEvent("Web:Client:AiReveals:BuyTokens");
    togglePaywall(true);
  }

  return (
    <>
      <div className="flex items-center whitespace-nowrap">
        {isMobile ? (
          <>
            <FormattedMessage id="ai_reveal_token_available_msg" />
            <Badge color="grey" className="px-0 pl-1 ml-2">
              <span className="px-1">{user?.getProfile?.aiCredits}</span>
              <IconButton
                color="dark"
                variant="square"
                icon="plus"
                onPress={buyTokens}
              />
            </Badge>
          </>
        ) : (
          <>
            {user?.getProfile?.aiCredits}{" "}
            <FormattedMessage id="ai_reveal_token_available_msg" />
            <Button
              color="gradient"
              variant="square"
              className="mx-3 whitespace-nowrap"
              onPress={buyTokens}
            >
              <FormattedMessage id="preview.buy_tokens" />
            </Button>
          </>
        )}
      </div>
    </>
  );
}
